
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as about_45usVifFxsGIq5Meta } from "/opt/render/project/src/apps/website/client/pages/about-us.vue?macro=true";
import { default as indexc8Y0mz73oyMeta } from "/opt/render/project/src/apps/website/client/pages/academy/index.vue?macro=true";
import { default as satisfaction_45form_45callback7BTs9E2X4bMeta } from "/opt/render/project/src/apps/website/client/pages/account/satisfaction-form-callback.vue?macro=true";
import { default as callbackBgCfsOWKkHMeta } from "/opt/render/project/src/apps/website/client/pages/auth/callback.vue?macro=true";
import { default as logina3Q4lkqyMUMeta } from "/opt/render/project/src/apps/website/client/pages/auth/login.vue?macro=true";
import { default as verifyse4KjB4GDKMeta } from "/opt/render/project/src/packages/core/client/pages/auth/v1/verify.vue?macro=true";
import { default as become_45trainerFj3Rqc2cz6Meta } from "/opt/render/project/src/apps/website/client/pages/become-trainer.vue?macro=true";
import { default as _91id_93getl3Md9cPMeta } from "/opt/render/project/src/apps/website/client/pages/blog/[id].vue?macro=true";
import { default as indexfiHTv4W8XzMeta } from "/opt/render/project/src/apps/website/client/pages/blog/index.vue?macro=true";
import { default as _91slug_93GcSkjJT3xfMeta } from "/opt/render/project/src/apps/website/client/pages/catalog/index/categories/[slug].vue?macro=true";
import { default as indexzAv2H2fhNAMeta } from "/opt/render/project/src/apps/website/client/pages/catalog/index/search/index.vue?macro=true";
import { default as indexPrWV6zdJHIMeta } from "/opt/render/project/src/apps/website/client/pages/catalog/index/top-trainers/index.vue?macro=true";
import { default as indexp4TEDiN5XMMeta } from "/opt/render/project/src/apps/website/client/pages/catalog/index.vue?macro=true";
import { default as _91certificateId_93lZSwvzsBPwMeta } from "/opt/render/project/src/apps/website/client/pages/certificates/[certificateId].vue?macro=true";
import { default as indexRwF4OrkzHNMeta } from "/opt/render/project/src/apps/website/client/pages/contact/index.vue?macro=true";
import { default as _91_46_46_46slug_93KakC9KXSUQMeta } from "/opt/render/project/src/apps/website/client/pages/content/[...slug].vue?macro=true";
import { default as indexYiVZBnecooMeta } from "/opt/render/project/src/apps/website/client/pages/courses/[slug]/index.vue?macro=true";
import { default as errorQrJVY4UvQIMeta } from "/opt/render/project/src/apps/website/client/pages/courses/[slug]/sessions/[sessionId]/enrol/index/error.vue?macro=true";
import { default as funding_45instructionsoQoKdvYFuSMeta } from "/opt/render/project/src/apps/website/client/pages/courses/[slug]/sessions/[sessionId]/enrol/index/funding-instructions.vue?macro=true";
import { default as indexkFVIFWTyLjMeta } from "/opt/render/project/src/apps/website/client/pages/courses/[slug]/sessions/[sessionId]/enrol/index/index.vue?macro=true";
import { default as successp3mS2v4U7RMeta } from "/opt/render/project/src/apps/website/client/pages/courses/[slug]/sessions/[sessionId]/enrol/index/success.vue?macro=true";
import { default as indexCRiJMPbiS4Meta } from "/opt/render/project/src/apps/website/client/pages/courses/[slug]/sessions/[sessionId]/enrol/index.vue?macro=true";
import { default as _91certificateId_93joU8hg7JcsMeta } from "/opt/render/project/src/apps/website/client/pages/documents/index/certificates/[certificateId].vue?macro=true";
import { default as email_45coverYhekdTLsDTMeta } from "/opt/render/project/src/apps/website/client/pages/documents/index/courses/[courseId]/email-cover.vue?macro=true";
import { default as og_45imagemT1KPmlaQ2Meta } from "/opt/render/project/src/apps/website/client/pages/documents/index/courses/[courseId]/og-image.vue?macro=true";
import { default as programuQpOCUHegvMeta } from "/opt/render/project/src/apps/website/client/pages/documents/index/courses/[courseId]/program.vue?macro=true";
import { default as coversFnV0BDjUhMeta } from "/opt/render/project/src/apps/website/client/pages/documents/index/courses/[courseId]/sessions/[sessionId]/cover.vue?macro=true";
import { default as og_45imageFfI3VK6znFMeta } from "/opt/render/project/src/apps/website/client/pages/documents/index/courses/[courseId]/sessions/[sessionId]/og-image.vue?macro=true";
import { default as signatureBrWodd19dxMeta } from "/opt/render/project/src/apps/website/client/pages/documents/index/courses/[courseId]/sessions/[sessionId]/signature.vue?macro=true";
import { default as indexYiAWITOTEmMeta } from "/opt/render/project/src/apps/website/client/pages/documents/index.vue?macro=true";
import { default as indexLBBjxUXUFxMeta } from "/opt/render/project/src/apps/website/client/pages/enterprise/index.vue?macro=true";
import { default as indexLUfbE2NpayMeta } from "/opt/render/project/src/apps/website/client/pages/events/[slug]/index.vue?macro=true";
import { default as indexuUZzdb88v5Meta } from "/opt/render/project/src/apps/website/client/pages/events/index.vue?macro=true";
import { default as getting_45financed4MVsOTDzWEMeta } from "/opt/render/project/src/apps/website/client/pages/getting-financed.vue?macro=true";
import { default as indexWw8b47hCi6Meta } from "/opt/render/project/src/apps/website/client/pages/index/index.vue?macro=true";
import { default as indexpWfRL0qyh5Meta } from "/opt/render/project/src/apps/website/client/pages/methodology/index.vue?macro=true";
import { default as onboardingAbxp6TKXYyMeta } from "/opt/render/project/src/apps/website/client/pages/onboarding.vue?macro=true";
import { default as indexCDOuAj2cYbMeta } from "/opt/render/project/src/apps/website/client/pages/partnerships/act-for-impact/index.vue?macro=true";
import { default as index972b9qvvgrMeta } from "/opt/render/project/src/apps/website/client/pages/partnerships/malt/index.vue?macro=true";
import { default as restricted_45accessncbSPpuif6Meta } from "/opt/render/project/src/apps/website/client/pages/restricted-access.vue?macro=true";
import { default as indexdypZowg5GhMeta } from "/opt/render/project/src/apps/website/client/pages/trainers/[slug]/index.vue?macro=true";
import { default as component_45stubb0U7dUfbyWMeta } from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.0_@types+node@22.10.3_db0@0.2.1_encoding@0.1.13_eslint@9.13.0_75u3cgfdepinttgd3hkxgdw5di/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubb0U7dUfbyW } from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.0_@types+node@22.10.3_db0@0.2.1_encoding@0.1.13_eslint@9.13.0_75u3cgfdepinttgd3hkxgdw5di/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "about-us",
    path: "/about-us",
    meta: about_45usVifFxsGIq5Meta || {},
    component: () => import("/opt/render/project/src/apps/website/client/pages/about-us.vue")
  },
  {
    name: "academy",
    path: "/academy",
    component: () => import("/opt/render/project/src/apps/website/client/pages/academy/index.vue")
  },
  {
    name: "account-satisfaction-form-callback",
    path: "/account/satisfaction-form-callback",
    meta: satisfaction_45form_45callback7BTs9E2X4bMeta || {},
    component: () => import("/opt/render/project/src/apps/website/client/pages/account/satisfaction-form-callback.vue")
  },
  {
    name: "auth-callback",
    path: "/auth/callback",
    meta: callbackBgCfsOWKkHMeta || {},
    component: () => import("/opt/render/project/src/apps/website/client/pages/auth/callback.vue")
  },
  {
    name: "auth-login",
    path: "/auth/login",
    meta: logina3Q4lkqyMUMeta || {},
    component: () => import("/opt/render/project/src/apps/website/client/pages/auth/login.vue")
  },
  {
    name: "auth-v1-verify",
    path: "/auth/v1/verify",
    meta: verifyse4KjB4GDKMeta || {},
    component: () => import("/opt/render/project/src/packages/core/client/pages/auth/v1/verify.vue")
  },
  {
    name: "become-trainer",
    path: "/become-trainer",
    component: () => import("/opt/render/project/src/apps/website/client/pages/become-trainer.vue")
  },
  {
    name: "blog-id",
    path: "/blog/:id()",
    meta: _91id_93getl3Md9cPMeta || {},
    component: () => import("/opt/render/project/src/apps/website/client/pages/blog/[id].vue")
  },
  {
    name: "blog",
    path: "/blog",
    meta: indexfiHTv4W8XzMeta || {},
    component: () => import("/opt/render/project/src/apps/website/client/pages/blog/index.vue")
  },
  {
    name: "catalog",
    path: "/catalog",
    component: () => import("/opt/render/project/src/apps/website/client/pages/catalog/index.vue"),
    children: [
  {
    name: "catalog-index-categories-slug",
    path: "categories/:slug()",
    component: () => import("/opt/render/project/src/apps/website/client/pages/catalog/index/categories/[slug].vue")
  },
  {
    name: "catalog-index-search",
    path: "search",
    meta: indexzAv2H2fhNAMeta || {},
    component: () => import("/opt/render/project/src/apps/website/client/pages/catalog/index/search/index.vue")
  },
  {
    name: "catalog-index-top-trainers",
    path: "top-trainers",
    component: () => import("/opt/render/project/src/apps/website/client/pages/catalog/index/top-trainers/index.vue")
  }
]
  },
  {
    name: "certificates-certificateId",
    path: "/certificates/:certificateId()",
    meta: _91certificateId_93lZSwvzsBPwMeta || {},
    component: () => import("/opt/render/project/src/apps/website/client/pages/certificates/[certificateId].vue")
  },
  {
    name: "contact",
    path: "/contact",
    meta: indexRwF4OrkzHNMeta || {},
    component: () => import("/opt/render/project/src/apps/website/client/pages/contact/index.vue")
  },
  {
    name: "content-slug",
    path: "/content/:slug(.*)*",
    meta: _91_46_46_46slug_93KakC9KXSUQMeta || {},
    component: () => import("/opt/render/project/src/apps/website/client/pages/content/[...slug].vue")
  },
  {
    name: "courses-slug",
    path: "/courses/:slug()",
    component: () => import("/opt/render/project/src/apps/website/client/pages/courses/[slug]/index.vue")
  },
  {
    name: indexCRiJMPbiS4Meta?.name,
    path: "/courses/:slug()/sessions/:sessionId()/enrol",
    meta: indexCRiJMPbiS4Meta || {},
    component: () => import("/opt/render/project/src/apps/website/client/pages/courses/[slug]/sessions/[sessionId]/enrol/index.vue"),
    children: [
  {
    name: "courses-slug-sessions-sessionId-enrol-index-error",
    path: "error",
    meta: errorQrJVY4UvQIMeta || {},
    component: () => import("/opt/render/project/src/apps/website/client/pages/courses/[slug]/sessions/[sessionId]/enrol/index/error.vue")
  },
  {
    name: "courses-slug-sessions-sessionId-enrol-index-funding-instructions",
    path: "funding-instructions",
    component: () => import("/opt/render/project/src/apps/website/client/pages/courses/[slug]/sessions/[sessionId]/enrol/index/funding-instructions.vue")
  },
  {
    name: "courses-slug-sessions-sessionId-enrol-index",
    path: "",
    meta: indexkFVIFWTyLjMeta || {},
    component: () => import("/opt/render/project/src/apps/website/client/pages/courses/[slug]/sessions/[sessionId]/enrol/index/index.vue")
  },
  {
    name: "courses-slug-sessions-sessionId-enrol-index-success",
    path: "success",
    component: () => import("/opt/render/project/src/apps/website/client/pages/courses/[slug]/sessions/[sessionId]/enrol/index/success.vue")
  }
]
  },
  {
    name: "documents",
    path: "/documents",
    meta: indexYiAWITOTEmMeta || {},
    component: () => import("/opt/render/project/src/apps/website/client/pages/documents/index.vue"),
    children: [
  {
    name: "documents-index-certificates-certificateId",
    path: "certificates/:certificateId()",
    component: () => import("/opt/render/project/src/apps/website/client/pages/documents/index/certificates/[certificateId].vue")
  },
  {
    name: "documents-index-courses-courseId-email-cover",
    path: "courses/:courseId()/email-cover",
    component: () => import("/opt/render/project/src/apps/website/client/pages/documents/index/courses/[courseId]/email-cover.vue")
  },
  {
    name: "documents-index-courses-courseId-og-image",
    path: "courses/:courseId()/og-image",
    component: () => import("/opt/render/project/src/apps/website/client/pages/documents/index/courses/[courseId]/og-image.vue")
  },
  {
    name: "documents-index-courses-courseId-program",
    path: "courses/:courseId()/program",
    meta: programuQpOCUHegvMeta || {},
    component: () => import("/opt/render/project/src/apps/website/client/pages/documents/index/courses/[courseId]/program.vue")
  },
  {
    name: "documents-index-courses-courseId-sessions-sessionId-cover",
    path: "courses/:courseId()/sessions/:sessionId()/cover",
    component: () => import("/opt/render/project/src/apps/website/client/pages/documents/index/courses/[courseId]/sessions/[sessionId]/cover.vue")
  },
  {
    name: "documents-index-courses-courseId-sessions-sessionId-og-image",
    path: "courses/:courseId()/sessions/:sessionId()/og-image",
    component: () => import("/opt/render/project/src/apps/website/client/pages/documents/index/courses/[courseId]/sessions/[sessionId]/og-image.vue")
  },
  {
    name: "documents-index-courses-courseId-sessions-sessionId-signature",
    path: "courses/:courseId()/sessions/:sessionId()/signature",
    meta: signatureBrWodd19dxMeta || {},
    component: () => import("/opt/render/project/src/apps/website/client/pages/documents/index/courses/[courseId]/sessions/[sessionId]/signature.vue")
  }
]
  },
  {
    name: "enterprise",
    path: "/enterprise",
    component: () => import("/opt/render/project/src/apps/website/client/pages/enterprise/index.vue")
  },
  {
    name: "events-slug",
    path: "/events/:slug()",
    component: () => import("/opt/render/project/src/apps/website/client/pages/events/[slug]/index.vue")
  },
  {
    name: "events",
    path: "/events",
    meta: indexuUZzdb88v5Meta || {},
    component: () => import("/opt/render/project/src/apps/website/client/pages/events/index.vue")
  },
  {
    name: "getting-financed",
    path: "/getting-financed",
    component: () => import("/opt/render/project/src/apps/website/client/pages/getting-financed.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/opt/render/project/src/apps/website/client/pages/index/index.vue")
  },
  {
    name: "methodology",
    path: "/methodology",
    component: () => import("/opt/render/project/src/apps/website/client/pages/methodology/index.vue")
  },
  {
    name: "onboarding",
    path: "/onboarding",
    meta: onboardingAbxp6TKXYyMeta || {},
    component: () => import("/opt/render/project/src/apps/website/client/pages/onboarding.vue")
  },
  {
    name: "partnerships-act-for-impact",
    path: "/partnerships/act-for-impact",
    meta: indexCDOuAj2cYbMeta || {},
    component: () => import("/opt/render/project/src/apps/website/client/pages/partnerships/act-for-impact/index.vue")
  },
  {
    name: "partnerships-malt",
    path: "/partnerships/malt",
    meta: index972b9qvvgrMeta || {},
    component: () => import("/opt/render/project/src/apps/website/client/pages/partnerships/malt/index.vue")
  },
  {
    name: "restricted-access",
    path: "/restricted-access",
    meta: restricted_45accessncbSPpuif6Meta || {},
    component: () => import("/opt/render/project/src/apps/website/client/pages/restricted-access.vue")
  },
  {
    name: "trainers-slug",
    path: "/trainers/:slug()",
    component: () => import("/opt/render/project/src/apps/website/client/pages/trainers/[slug]/index.vue")
  },
  {
    path: "/content/legal-notices"
  },
  {
    path: "/content/privacy-policy"
  },
  {
    path: "/content/rules-of-procedure"
  },
  {
    path: "/content/terms-and-conditions"
  },
  {
    name: component_45stubb0U7dUfbyWMeta?.name,
    path: "/search",
    component: component_45stubb0U7dUfbyW
  },
  {
    name: component_45stubb0U7dUfbyWMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubb0U7dUfbyW
  }
]