import validate from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.0_@types+node@22.10.3_db0@0.2.1_encoding@0.1.13_eslint@9.13.0_75u3cgfdepinttgd3hkxgdw5di/node_modules/nuxt/dist/pages/runtime/validate.js";
import _0_45restricted_45access_45middleware_45global from "/opt/render/project/src/apps/website/client/middleware/0.restricted-access.middleware.global.ts";
import authentication_45middleware_45global from "/opt/render/project/src/apps/website/client/middleware/authentication.middleware.global.ts";
import manifest_45route_45rule from "/opt/render/project/src/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.0_@types+node@22.10.3_db0@0.2.1_encoding@0.1.13_eslint@9.13.0_75u3cgfdepinttgd3hkxgdw5di/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _0_45restricted_45access_45middleware_45global,
  authentication_45middleware_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}